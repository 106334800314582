rect[fill="#39A86B"] {
  opacity: 0.6;
}

rect.dayBar:hover {
  opacity: 0.4;
}

.duration-box-wrapper {
  margin-top: 5px;
  padding: 5px;
  background: #e4ebf7;
}
