.open-incidents-card {
  margin-top: 20px;
}

.current-status {
  padding: 25px;
}

.current-status.severity-0 {
  background: #39A86B;
  color: white;
}

.current-status.severity-1 {
  background: #1C89E8;
  color: white;
}

.current-status.severity-2 {
  background: #FEFF9F;
  color: black;
}

.current-status.severity-3 {
  background: #E7851B;
  color: white;
}

.current-status.severity-4 {
  background: #DC3545;
  color: white;
}
